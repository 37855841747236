<template>
    <Layout>
      <template v-if="this.$route.name == 'add-campaign-brand-speciality-data'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" id="medinext">
              <b-form enctype="multipart/form-data" ref="promotion">
                <div class="row">
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_brand_speciality_name">Campaign Brand Speciality Name <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_brand_speciality_name"
                        placeholder="Enter Campaign Brand Speciality Name"
                        v-model="form.name"
                        :class="{
                          'is-invalid': submitted && $v.form.name.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                            Campaign Brand Speciality Name is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_brand_speciality_key">Campaign Speciality key <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_brand_speciality_key"
                        placeholder="Enter Campaign Brand Speciality key"
                        v-model="form.key"
                        :class="{
                          'is-invalid': submitted && $v.form.key.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.key.required" class="invalid-feedback">
                            Campaign Brand Speciality key is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_speciality_heading">Campaign Speciality Heading <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_heading"
                        placeholder="Enter Speciality Heading"
                        v-model="form.heading"
                        :class="{
                          'is-invalid': submitted && $v.form.heading.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.heading.required" class="invalid-feedback">
                            Campaign Speciality Heading is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_speciality_sub_heading">Campaign Speciality Sub Heading <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_sub_heading"
                        placeholder="Enter Speciality Sub Heading"
                        v-model="form.sub_heading"
                        :class="{
                          'is-invalid': submitted && $v.form.sub_heading.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.sub_heading.required" class="invalid-feedback">
                            Campaign Speciality Sub Heading is required.
                        </div>
                    </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Brand Speciaility Backgroud Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="backgroud_image"
                      @change="readFile($event, 'background_image')"
                      ref="background_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.background_img_url"
                    >
                      <img
                        :src="edit.background_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="background_img_url">
                      <img
                        :src="background_img_url"
                        width="128px"
                        height="128px"
                        ref="background_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Brand Speciaility Paperless Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="paperless_image"
                      @change="readFile($event, 'paperless_image')"
                      ref="paperless_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.paperless_img_url"
                    >
                      <img
                        :src="edit.paperless_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="paperless_img_url">
                      <img
                        :src="paperless_img_url"
                        width="128px"
                        height="128px"
                        ref="paperless_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group v-if="$route.name == 'edit-campaign-brand-speciality-data'" class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Brand Speciaility Icon Quality Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="icon_quality"
                      @change="readFile($event, 'icon_quality')"
                      ref="icon_quality"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.icon_quality_img_url"
                    >
                      <img
                        :src="edit.icon_quality_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="icon_quality_img_url">
                      <img
                        :src="icon_quality_img_url"
                        width="128px"
                        height="128px"
                        ref="icon_quality_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group v-if="$route.name == 'edit-campaign-brand-speciality-data'" class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Speciaility Icon Brand Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="icon_brand"
                      @change="readFile($event, 'icon_brand')"
                      ref="icon_brand"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.icon_brand_img_url"
                    >
                      <img
                        :src="edit.icon_brand_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="icon_brand_img_url">
                      <img
                        :src="icon_brand_img_url"
                        width="128px"
                        height="128px"
                        ref="icon_brand_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group v-if="$route.name == 'edit-campaign-brand-speciality-data'" class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Brand Speciaility Logo Icon</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="icon_logo"
                      @change="readFile($event, 'icon_logo')"
                      ref="icon_logo"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.icon_logo_img_url"
                    >
                      <img
                        :src="edit.icon_logo_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="icon_logo_img_url">
                      <img
                        :src="icon_logo_img_url"
                        width="128px"
                        height="128px"
                        ref="border_icon_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group v-if="$route.name == 'edit-campaign-brand-speciality-data'" class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Brand Speciaility Border Icon</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="border_icon"
                      @change="readFile($event, 'border_icon')"
                      ref="border_icon"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-brand-speciality-data' && edit.border_icon_img_url"
                    >
                      <img
                        :src="edit.border_icon_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="border_icon_img_url">
                      <img
                        :src="border_icon_img_url"
                        width="128px"
                        height="128px"
                        ref="border_icon_img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_dark_color">Campaign Brand Speciality Dark Color <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_dark_color"
                        placeholder="Enter Brand Campaign Speciality Dark Color"
                        v-model="form.dark_color"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_light_color">Campaign Brand Speciality Light Color <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_speciality_light_color"
                        placeholder="Enter Brand Campaign Speciality Light Color"
                        v-model="form.light_color"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span v-if="this.$route.name == 'add-campaign-brand-speciality-data'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import mrappcampaignBrandSpecDataMixin from "../../../../mixins/ModuleJs/campaign-brand-speciality-data";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    data() {
      return {
        submitted: false,
        title1: "Add Campaign Brand Speciality Data",
        title2: "Edit Campaign Brand Speciaility Data",
        items: [
          {
            text: "List",
            href: "/field-rep-app/campaign-brand-speciality-data",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, mrappcampaignBrandSpecDataMixin],
    components: {
      Layout,
      PageHeader
    },
    validations: {
      form: {
        name: { required },
        key: { required },
        heading: { required },
        sub_heading: { required }
      },
    },
  };
  </script>
  